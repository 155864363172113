$(document).ready(function () {
	//Declare normal variable javascript
	//Hide element when smaller than 1025

	// $(".search-toggle").on("click", function (e) {
	// 	$(".searchbox").toggleClass("active");
	// 	e.stopPropagation();
	// });


	$(".search-toggle").on("click", function () {
		$(".searchbox").toggleClass("open");
		event.preventDefault();
		$('.searchbox  > input[type="text"]').focus();
	});

	$(".searchbox, .searchbox .close").on("click keyup", function (event) {
		if (
			event.target == this ||
			event.target.className == "close" ||
			event.keyCode == 27
		) {
			$(this).removeClass("open");
		}
	});
	if ($(window).width() < 1025) {
		$(".main-menu-nav").fadeIn(function () {
			$(".main-menu-nav").css({
				display: "flex"
			});
		});
	}
	//Library Base function
	$('[data-fancybox=""]').fancybox({
		smallBtn: true,
		toolbar: true,
		type: "html",
		clickSlide: 'toggleControls'
	});
	try {
		stickyNav();
	} catch (error) {}

	tabActive();
	toggleMobileMenu();
	toggleWatchMore();
	toggleSearch();
	swiperInit();
	toggleSupport();

	//Declare NEW function Javascript
	//Fullpage Js
	if ($(window).width() > 1024.1) {
		if ($("body").hasClass("homepage")) {

			$('#fullpage').fullpage({
				//Navigation
				//Navigation
				licenseKey: 'A3DA879C-B1254377-8A906973-AAE812EA',
				bigSectionsDestination: top,
				menu: '#menu-parallax',
				lockAnchors: false,
				anchors: ['banner', 'story', 'produce', 'product', 'Dealer', 'community', 'footer'],
				navigation: true,
				navigationPosition: 'right',
				navigationTooltips: ['Banner', 'Story', 'Produce', 'Product', 'Dealer', 'Community', 'Footer'],
				showActiveTooltip: false,
				slidesNavigation: true,
				slidesNavPosition: 'bottom',
				//Scrolling
				css3: true,
				scrollingSpeed: 700,
				autoScrolling: true,
				fitToSection: true,
				fitToSectionDelay: 1000,
				scrollBar: false,
				easing: 'easeInOutCubic',
				easingcss3: 'ease',
				loopBottom: false,
				loopTop: false,
				loopHorizontal: true,
				continuousVertical: false,
				scrollOverflow: false,
				touchSensitivity: 15,
				normalScrollElementTouchThreshold: 5,

				//Accessibility
				keyboardScrolling: true,
				animateAnchor: true,
				recordHistory: true,

				//Design
				controlArrows: true,
				verticalCentered: true,
				resize: false,
				responsiveWidth: 0,
				responsiveHeight: 0,

				//Custom selectors
				sectionSelector: 'section',
				dragAndMove: true,
				onLeave: function (origin, destination, direction) {
					var loadedSection = this;

					//using index
					if (origin.index != 1) {
						$('header').addClass('minimize')
					} else if (origin.index == 1 && direction == 'up') {
						$('header').removeClass('minimize')
					}

					//using anchorLink

				}
			});


		}
	}

});



function toggleSupport() {
	$(".toggle-item > .title").click(function (e) {
		e.preventDefault();
		if (
			!$(this)
			.parent()
			.hasClass("active")
		) {
			$(".toggle-item article").slideUp();
			$(this)
				.next()
				.slideToggle();
			$(".toggle-item").removeClass("active");
			$(this)
				.parent()
				.addClass("active");
		} else {
			$(this)
				.next()
				.slideToggle();
			$(".toggle-item").removeClass("active");
		}
	});
}
//Toggle mobile menu
function toggleMobileMenu() {
	var $hamburger = $(".hamburger");
	$(".main-menu-toggle").on("click", function () {
		$("header").toggleClass("active");
		$("main").toggleClass("overlay");
		$hamburger.toggleClass("is-active");
	});
}

function toggleSearch() {

}

function swiperInit() {
	var homerSwiper = new Swiper(".primary-banner .swiper-container", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000
		},

	});
	var topBanner = new Swiper(".top-banner .swiper-container", {
		// Optional parameters
		speed: 1205,
		slidesPerView: 1,
		autoplay: {
			delay: 3000
		}
	});

	$(" .four-item-swiper .swiper-container").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-" + index);
		$this.parent().find(".nav-arrow-prev").addClass("btn-prev-" + index);
		$this.parent().find(".nav-arrow-next").addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-" + index, {
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpointsInverse: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				450: {
					slidesPerView: 2,
				},
				576: {
					slidesPerView: 2,
				},
				1024: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index
			}

		});
	});
	var aboutAward = new Swiper(".about-award .swiper-container", {
		// Optional parameters
		speed: 1205,
		spaceBetween: 30,
		breakpointsInverse: true,
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			769: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
		},
		autoplay: {
			delay: 3000
		},

		navigation: {
			nextEl: ".about-award .nav-arrow-next",
			prevEl: ".about-award .nav-arrow-prev"
		}
	});
	var homeCommunity = new Swiper(".home-community-swiper .swiper-container", {
		// Optional parameters
		speed: 1205,
		spaceBetween: 10,
		breakpointsInverse: true,
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			769: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 4,
			},
		},
		autoplay: {
			delay: 3000
		},


	});
	var homeProduct = new Swiper(".home-product .swiper-container", {
		// Optional parameters
		speed: 1205,
		spaceBetween: 30,
		breakpointsInverse: true,
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 2,
			},
			769: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 3,
			},
		},
		autoplay: {
			delay: 3000
		},

		navigation: {
			nextEl: ".home-product .nav-arrow-next",
			prevEl: ".home-product .nav-arrow-prev"
		}
	});
	var singleSwiper = new Swiper(".single-swiper .swiper-container", {
		// Optional parameters
		speed: 1205,

		autoplay: {
			delay: 3000
		},

		navigation: {
			nextEl: ".single-swiper .nav-arrow-next",
			prevEl: ".single-swiper .nav-arrow-prev"
		}
	});
	var galleryThumbs = new Swiper('.swiper-product-thumb .swiper-container', {
		spaceBetween: 10,
		breakpointsInverse: true,
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 3,
			},
			769: {
				slidesPerView: 3,
			},
			1200: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 4,
			},
		},
		freeMode: true,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var galleryTop = new Swiper('.swiper-product-top .swiper-container', {
		spaceBetween: 0,
		thumbs: {
			swiper: galleryThumbs
		}
	});
}


//

//Toggle Plugin

function toggleItem() {
	$(".toggle-item > .title").click(function (e) {
		e.preventDefault();
		if (
			!$(this)
			.parent()
			.hasClass("active")
		) {
			$(".toggle-item .content").slideUp();
			$(this)
				.next()
				.slideToggle();
			$(".toggle-item").removeClass("active");
			$(this)
				.parent()
				.addClass("active");
		} else {
			$(this)
				.next()
				.slideToggle();
			$(".toggle-item").removeClass("active");
		}
	});
}




//Toggle watch more

function toggleWatchMore() {

	let button = $(".read-more-wrapper .btn-view-more");
	let content = $(".read-more-wrapper .content-text ");
	button.on("click", function () {
		content.toggleClass('active')
	});
}

//Sticky navigation

function stickyNav() {
	if ($(".sticky-nav").length) {
		$(".sticky-nav").scrollToFixed({
			zIndex: 99,
			marginTop: $("header").outerHeight(),
		});
		$(".sticky-nav a").on("click", function (event) {
			if (this.hash !== "") {
				let offset =
					$("header").outerHeight() + $(".sticky-nav").outerHeight();
				var hash = this.hash;
				$(".sticky-nav li").removeClass('active')
				$(this).parent().addClass('active')
				$("html, body").animate({
						scrollTop: $(hash).offset().top - offset,
					},
					800,
					function () {
						window.location.hash = hash;
					}
				);
			} // End if
		});
	}
}

//Tab active

function tabActive() {
	$(".tab-navigation li a").on("click", function () {
		$(this)
			.parents(".tab-navigation")
			.find("li")
			.removeClass("active");
		$(this)
			.parents("li")
			.addClass("active");

		var display = $(this).attr("data-type");
		$(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}

// Side navigation

$(function () {
	var $ul = $(".sidebar-navigation > ul");

	$ul.find("li .title em").click(function (e) {
		var $li = $(this)
			.parent()
			.parent();

		if ($li.find("ul").length > 0) {
			e.preventDefault();

			if ($li.hasClass("selected")) {
				$li.removeClass("selected")
					.find("li")
					.removeClass("selected");
				$li.find("ul").slideUp(400);
				$li.find(".title em").removeClass("mdi-flip-v");
			} else {
				if ($li.parents("li.selected").length == 0) {
					$ul.find("li").removeClass("selected");
					$ul.find("ul").slideUp(400);
					$ul.find("li .title em").removeClass("mdi-flip-v");
				} else {
					$li.parent()
						.find("li")
						.removeClass("selected");
					$li.parent()
						.find("> li ul")
						.slideUp(400);
					$li.parent()
						.find("> li .title em")
						.removeClass("mdi-flip-v");
				}

				$li.addClass("selected");
				$li.find(">ul").slideDown(400);
				$li.find(">.title>em").addClass("mdi-flip-v");
			}
		}
	});

	$(".sidebar-navigation > ul ul").each(function (i) {
		if ($(this).find(">li>ul").length > 0) {
			var paddingLeft = $(this)
				.parent()
				.parent()
				.find(">li>.title")
				.css("padding-left");
			var pIntPLeft = parseInt(paddingLeft);
			var result = pIntPLeft + 30;

			$(this)
				.find(">li>.title")
				.css("padding-left", result);
		} else {
			var paddingLeft = $(this)
				.parent()
				.parent()
				.find(">li>.title")
				.css("padding-left");
			var pIntPLeft = parseInt(paddingLeft);
			var result = pIntPLeft + 0;

			$(this)
				.find(">li>.title")
				.css("padding-left", result)
				.parent()
				.addClass("selected--last");
		}
	});

	var t = " li > ul ";
	for (var i = 1; i <= 10; i++) {
		$(".sidebar-navigation > ul > " + t.repeat(i)).addClass(
			"sub-menu-" + i
		);
	}

	var activeLi = $("li.selected");
	if (activeLi.length) {
		opener(activeLi);
	}

	function opener(li) {
		var ul = li.closest("ul");
		if (ul.length) {
			li.addClass("selected");
			ul.addClass("open");
			li.find(">.title>em").addClass("mdi-flip-v");

			if (ul.closest("li").length) {
				opener(ul.closest("li"));
			} else {
				return false;
			}
		}
	}
});
